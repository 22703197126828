import { AnyAction } from 'redux'
import _get from 'lodash/get'

import * as types from './channel-action-types'
import { TSenderId } from '../../ui/extension-contact/extension-contact-types'

export type TChannelState = {
  ready: boolean
  senderIds: TSenderId[]
  error?: string
  isSendingConfig?: boolean
  alertMessage?: string
  alertType?: 'error' | 'success' | 'notification'
}

export const initialState: TChannelState = {
  ready: false,
  senderIds: [],
}

export default function channelReducer(
  state: TChannelState = initialState,
  action: AnyAction): TChannelState {
  switch (action.type) {
    case types.GET_CHANNEL_SENDER_IDS.REQUEST:
      return {
        ...state,
        ready: false,
        alertMessage: undefined,
      }
    case types.GET_CHANNEL_SENDER_IDS.SUCCESS:
      return {
        ...state,
        ready: true,
        senderIds: _get(action, 'payload', []) as TSenderId[],
        alertMessage: undefined,
      }
    case types.GET_CHANNEL_SENDER_IDS.FAILURE:
      return {
        ...state,
        ready: true,
        senderIds: [] as TSenderId[],
        alertMessage: 'Could not retrieve sender ids. Please try again later.',
        alertType: 'error',
      }
    case types.SEND_CHANNEL_CONFIG.REQUEST:
      return {
        ...state,
        isSendingConfig: true,
        alertMessage: undefined,
      }
    case types.SEND_CHANNEL_CONFIG.SUCCESS:
      return {
        ...state,
        isSendingConfig: false,
        alertMessage: 'Channel configuration saved.',
        alertType: 'success',
      }
    case types.SEND_CHANNEL_CONFIG.FAILURE:
      return {
        ...state,
        isSendingConfig: false,
        alertMessage: 'Could not save channel configuration at this moment. Please try again later.',
        alertType: 'notification',
      }
    default:
      return state
  }
}
