import React, { useState } from 'react'
import { Field, useFormState, useForm } from 'react-final-form'
import Row from 'antd/lib/grid/row'
import Col from 'antd/lib/grid/col'
import Select from 'antd/lib/select'
import Icon from '@ant-design/icons'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'

import withFinalForm from '../../core/with-final-form'
import WhenFieldChanges from '../../when-field-changes'
import SMSCharacterCount from '../../sms-character-counter/pure-sms-character-counter'
import MMSMessageCounter from '../../mms-message-counter/mms-message-counter'
import TextArea from '../../antd-text-area'
import WarningMessage from '../../warning-message/warning-message'
import PersonaliseMessage from '../../personalise-dropdown'
import {
  composeValidators,
  maximumSMS,
  isValidUnicode,
  validateMessageContent,
} from '../../../helpers/form-validations/form-validations'
import {
  TFormValues,
  TExtensionContactFormFieldProps,
} from '../extension-contact-types'
import { UNICODE_SUPPORT } from '../../../constants/extension-contact'

import { ReactComponent as TemplatesIcon } from '../../../assets/img/icons/template.svg'
import './message-box.css'
import insertValueAtCurrentCursor from '../../../helpers/insert-value-at-current-cursor/insert-value-at-current-cursor'

import { useAppSelector } from '../../../store/hooks'
import { getDetails, getIsTrialUser, getTFNTrialContent } from '../../../redux/contact/extension-contact-selectors'
import { getSelfServeOnboardingFlag } from '../../../redux/release-flags/release-flags-selector'

export type TMessageBoxProps = {
  type: 'SMS' | 'MMS'
  mmsFileSize?: number
  replaceMessage: (newContent: string) => void
} & TExtensionContactFormFieldProps

const { Option } = Select

const SelectComp = withFinalForm()(Select)

function MessageBox(props: TMessageBoxProps) {
  const [prevSelectedTemplate, setPrevSelectedTemplate] = useState<undefined | number>(undefined)
  const { values, errors, dirtyFields } = useFormState<TFormValues>()
  const { change } = useForm()
  const ref = React.useRef(null)
  const contactDetails = useAppSelector(getDetails)
  const isSelfServeEnabled = useAppSelector(getSelfServeOnboardingFlag)
  const isTrial = useAppSelector(getIsTrialUser)
  const TFNTrialContent = useAppSelector(getTFNTrialContent)
  const intl = useIntl()

  const isTrialUser = isSelfServeEnabled && isTrial
  const { messageTemplates, supportedTemplateMergeFields } = contactDetails

  const {
    processing,
    type,
    mmsFileSize,
    replaceMessage,
  } = props

  const resetTemplate = () => {
    setPrevSelectedTemplate(values[`templates${type}`])
    /* istanbul ignore else */
    if (prevSelectedTemplate === values[`templates${type}`]) {
      change(`templates${type}`, undefined)
      setPrevSelectedTemplate(undefined)
    }
  }

  const addPersonalisedText = (value: string) => {
    insertValueAtCurrentCursor(
      _get(ref, 'current.resizableTextArea.textArea'),
      `#${value}#`,
      { onChange: replaceMessage },
    )
  }

  const isInvalid = Boolean(dirtyFields?.[`messageContent${type}`] && errors?.[`messageContent${type}`])
  const isValidUnicodeVisible = !_isEmpty(isValidUnicode(UNICODE_SUPPORT)(values[`messageContent${type}`] as string))

  return (
    <div className="message-box">
      {
        type === 'MMS' && (
          <label htmlFor="message-content" className="label">
            <FormattedMessage
              id="message-box.message-content.label"
            />
          </label>
        )
      }
      <Row align="middle" className="row">
        <Col>
          <WhenFieldChanges
            field={`templates${type}`}
            set={`messageContent${type}`}
            to={_get(messageTemplates, ['templates', values[`templates${type}`], 'messageText'], '')}
          />

          <Field
            name={`templates${type}`}
          >
            {({ input }) => (
              <SelectComp
                {...input}
                className="templates-field"
                value={values[`templates${type}`]}
                bordered={false}
                disabled={isTrialUser || processing || _isEmpty(_get(messageTemplates, 'templates', []))}
                showSearch
                optionFilterProp="children"
                placeholder={(
                  <div className="placeholder">
                    <Icon component={TemplatesIcon} />
                    <span>
                      <FormattedMessage
                        id="message-box.select.templates.placeholder"
                      />
                    </span>
                  </div>
                )}
                dropdownClassName="templates-field-dropdown"
                dropdownStyle={{
                  maxWidth: _get(ref, 'current.resizableTextArea.textArea.offsetWidth', 0) + 32,
                }}
                dropdownMatchSelectWidth={false}
              >
                {
                  Option && (_get(messageTemplates, 'templates', [])).map(
                    (template, idx) => (
                      <Option
                        value={idx}
                        key={template.id}
                      >
                        {template.name}
                      </Option>
                    ),
                  )
                }
              </SelectComp>
            )}
          </Field>
          <PersonaliseMessage
            supportedTemplateMergeFields={supportedTemplateMergeFields as string[]}
            onChange={addPersonalisedText}
            disabled={isTrialUser}
          />
        </Col>
      </Row>

      {
        values.tabMessageContent === `TAB-${type}` && (
          <div className="message-content-container">
            <WhenFieldChanges
              field={`messageContent${type}`}
              cb={() => resetTemplate()}
            />
            <Field
              name={`messageContent${type}`}
              disabled={processing}
              initialValue={isTrialUser ? TFNTrialContent : ''}
              validate={composeValidators([
                validateMessageContent(contactDetails),
                maximumSMS(5000),
              ])}
            >
              {((fieldProps) => (
                <TextArea
                  rows={6}
                  placeholder={intl.formatMessage({ id: 'message-box.text-area.message-content.placeholder' })}
                  className={classnames({ error: isInvalid, disabled: isTrialUser })}
                  readOnly={isTrialUser}
                  {...fieldProps.input}
                  ref={ref}
                />
              ))}
            </Field>
          </div>
        )
      }
      <footer className="footer">
        {
          type === 'SMS' ? (
            <SMSCharacterCount
              name="message-count"
              message={values[`messageContent${type}`] as string}
            />
          ) : (
            <MMSMessageCounter
              name="message-count"
              mmsProps={{
                mmsFileSize: mmsFileSize as number,
                mmsSubjectSize: values.subject?.length || 0,
                content: values[`messageContent${type}`] as string,
              }}
            />
          )
        }
      </footer>

      <WarningMessage
        message={isValidUnicode(UNICODE_SUPPORT)(values[`messageContent${type}`] as string) as JSX.Element}
        isVisible={isValidUnicodeVisible}
      />

      <WarningMessage
        message={errors?.[`messageContent${type}`] as string}
        isVisible={values.tabMessageContent === `TAB-${type}` && isInvalid}
      />
    </div>
  )
}

export default MessageBox
