import React from 'react'
import classNames from 'classnames'

import styles from './heading.less'

type THeading = {
  className?: string
  name?: string
  weight?: number
  variant?: 'robotoSlab' | 'lato'
  children: React.ReactNode
}

function Heading({
  className,
  children,
  weight = 1,
  variant = 'lato',
  name,
}: THeading) {
  const Tag = `h${weight}` as unknown as keyof JSX.IntrinsicElements

  return (
    <Tag
      className={classNames(styles.heading,
        styles[`heading-${Tag}`],
        { [styles.lato]: variant === 'lato' },
        { [styles.robotoSlab]: variant === 'robotoSlab' },
        className,
      )}
      data-test={`heading-${name}`}
    >
      {children}
    </Tag>
  )
}

export default Heading
