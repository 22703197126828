import _get from 'lodash/get'
import _find from 'lodash/find'

import { TSenderIds } from '../../ui/extension-contact/extension-contact-types'

const checkMMSCapability = (senderIds: TSenderIds, value: string) => {
  const selectedSenderId = _find(senderIds, { number: value })
  const senderIdCapabilities = _get(selectedSenderId, ['capabilities'], null)
  const isMMSCapable = senderIdCapabilities && senderIdCapabilities.includes('MMS')
  return isMMSCapable || false
}

export default checkMMSCapability
