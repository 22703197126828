import React from 'react'
import classNames from 'classnames'

import styles from './typography.less'

type TTypography = {
  children: React.ReactNode
  className?: string
  dataTest?: string
  variant?: 'paragraph' | 'caption' | 'title' | 'subtitle'
  weight?: 'normal' | 'bold'
}

function Typography({
  children = undefined,
  className = undefined,
  dataTest = undefined,
  weight = 'normal',
  variant = 'paragraph',
}: TTypography) {
  const variantStyle = classNames(styles.default,
    { [styles.paragraph]: variant === 'paragraph' },
    { [styles.caption]: variant === 'caption' },
    { [styles.title]: variant === 'title' },
    { [styles.subtitle]: variant === 'subtitle' },
  )

  const weightStyle = classNames(styles.normal,
    { [styles.bold]: weight === 'bold' },
  )

  if (variant === 'paragraph') {
    return (
      <p className={classNames(className, variantStyle, weightStyle)} data-test={`typography-${dataTest}`}>
        {children}
      </p>
    )
  }

  return (
    <span className={classNames(className, variantStyle, weightStyle)} data-test={`typography-${dataTest}`}>
      {children}
    </span>
  )
}

export default Typography
