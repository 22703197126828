import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import Layout from 'antd/lib/layout'
import Card from 'antd/lib/card'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Alert from 'antd/lib/alert'
import Notification from 'antd/lib/notification'
import { useDispatch, useSelector } from 'react-redux'

import useQuery from '../../../../../hocs/use-query'
import withFinalForm from '../../../../../ui/core/with-final-form'
import Heading from '../../../../../ui/core/heading'
import { intl } from '../../../../../ui/i18n'
import {
  getChannelAlertMessage,
  getChannelReadyState,
  getChannelSenderIds,
  getSendingConfigState,
} from '../../../../../redux/channel/channel-selectors'
import { getSenderIds, sendConfiguration } from '../../../../../redux/channel/channel-actions'

import './Channel.css'
import { TSenderId } from '../../../../../ui/extension-contact/extension-contact-types'
import { fieldRequired } from '../../../../../helpers/form-validations/form-validations'

type TConfigChannelForm = {
  senderId: string
}

const { Content } = Layout
const SelectComp = withFinalForm()(Select)
const { Option } = Select

function Channel() {
  const query = useQuery()
  const dispatch = useDispatch()
  const [api, contextHolder] = Notification.useNotification()

  const portalId = query.get('portalId') || 'portalid'
  const channelId = query.get('channelId') || 'channelid'
  const accountToken = query.get('accountToken') || 'accounttoken'
  const inboxId = query.get('inboxId') || 'inboxid'

  const ready = useSelector(getChannelReadyState)
  const { alertMessage, alertType } = useSelector(getChannelAlertMessage)
  const senderIds = useSelector(getChannelSenderIds) as TSenderId[]
  const isSendingConfig = useSelector(getSendingConfigState)

  useEffect(() => {
    if (!ready) {
      const fetchSenderIds = async () => {
        dispatch(getSenderIds({ portalId }))
      }
      fetchSenderIds()
    }
  }, []) // eslint-disable-line

  const onSubmit = async (values: TConfigChannelForm) => {
    const body = {
      ...values,
      portalId,
      channelId,
      accountToken,
      inboxId,
    }

    try {
      await dispatch(sendConfiguration(body))
      setTimeout(() => {
        window.close()
      }, 3000)
    } catch (error) {
      api.error({
        message: 'Save Configuration Failed',
        description: alertMessage,
      })
    }
  }

  const renderContent = () => {
    if (!ready) {
      return (
        <div className="spinner-layout ">
          <Spin size="large" className="spin" />
        </div>
      )
    }
    if (alertMessage && alertType !== 'notification') {
      return null
    }

    return (
      <Card
        className="configure-channel-card"
        title={intl.formatMessage({ id: 'channels.configure-channel.title' })}
        data-test="configure-channel-card"
      >
        <Form
          onSubmit={onSubmit}
        >
          {({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Heading weight={4}>
                <FormattedMessage id="sender-and-recipients.card.sender-id.heading" />
              </Heading>
              <Field
                name="senderId"
                className="sender-id"
                component={SelectComp}
                showSearch
                validate={fieldRequired()}
                optionFilterProp="children"
                disabled={isSendingConfig}
              >
                {senderIds && senderIds.map((a) => (
                  <Option key={a.number} value={a.number} data-test={a.number}>
                    {a.displayLabel}
                  </Option>
                ))}
              </Field>
              <div className="button-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSendingConfig || invalid}
                >
                  <FormattedMessage id="channels.configure-channel.save-configuration" />
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Card>
    )
  }

  return (
    <Layout>
      {contextHolder}
      <Content>
        {
          alertMessage
          && alertType !== 'notification'
          && (
            <Alert message={alertMessage} type={alertType} showIcon />
          )
        }
        {renderContent()}
      </Content>
    </Layout>
  )
}

export default Channel
