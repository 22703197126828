import { Dispatch } from 'redux'

import * as types from './channel-action-types'

import ExtensionContactApi from '../../apis/extension-contact-api'

export type TChannelConfiguration = {
  senderId: string,
  portalId: string,
  channelId: string,
  accountToken: string,
  inboxId: string,
}

export const getSenderIds = (query: {portalId: string}) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.GET_CHANNEL_SENDER_IDS.REQUEST,
  })

  try {
    const response = await ExtensionContactApi.fetchSenderIds(query)
    dispatch({
      type: types.GET_CHANNEL_SENDER_IDS.SUCCESS,
      payload: await response.json(),
    })
  } catch (error) {
    dispatch({
      type: types.GET_CHANNEL_SENDER_IDS.FAILURE,
    })
    throw error
  }
}

export const sendConfiguration = (body: TChannelConfiguration) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.SEND_CHANNEL_CONFIG.REQUEST,
  })

  const response = await ExtensionContactApi.sendConfiguration(body)

  if (response.status === 200) {
    dispatch({
      type: types.SEND_CHANNEL_CONFIG.SUCCESS,
    })
    return
  }

  dispatch({
    type: types.SEND_CHANNEL_CONFIG.FAILURE,
  })
  throw new Error('Save configuration failed')
}
