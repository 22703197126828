import React from 'react'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'

import ExtensionContactFormFields from './extension-contact-form-fields'

import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { getPhoneNumbers, getDetails } from '../../../redux/contact/extension-contact-selectors'
import { setAlert, sendMessage } from '../../../redux/contact/extension-contact-actions'

import { TQueryState, TFormValues } from '../extension-contact-types'
import { TAB_SMS, TAB_MMS } from '../../../constants/extension-contact'
import getPhoneNumberFormat from '../../../helpers/get-phone-number-format'

function ExtensionContactForm(props: TQueryState) {
  const phoneNumbers = useAppSelector(getPhoneNumbers)
  const contactDetails = useAppSelector(getDetails)
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const onSubmit = async (values: TFormValues) => {
    const {
      otp,
      objectType,
      uuid,
      qsContactId,
      qsDealId,
      qsUserId,
    } = props
    const phoneNumber = getPhoneNumberFormat('E164', phoneNumbers, values.phoneSource)
    const messageContent = getMessageContent(values)
    const mmsFields = values.tabMessageContent === TAB_MMS && {
      mediaURL: values.mediaURL,
      subject: values.subject,
    }

    const query = {
      otp,
      objectType,
      uuid,
      qsContactId,
      qsDealId,
      qsUserId,
    }
    const body = {
      messageContent,
      senderId: values.senderId,
      phoneSource: values.phoneSource,
      ...mmsFields,
      phoneNumber,
      ...(values.accountId && { accountId: values.accountId }),
    }

    await dispatch(sendMessage({ query, body }))

    const intPhoneNumber = getPhoneNumberFormat('INTERNATIONAL', phoneNumbers, values.phoneSource)
    dispatch(setAlert({
      message: (
        intl.formatMessage(
          { id: 'alert.message-sent.success' },
          { phoneNumber: intPhoneNumber },
        )
      ),
      type: 'success',
    }))

    setTimeout(() => {
      window.parent.postMessage(JSON.stringify({ action: 'DONE' }), '*')
    }, 3000)
  }

  const getMessageContent = (values: Partial<TFormValues>) => (values.tabMessageContent === TAB_SMS ? values.messageContentSMS : values.messageContentMMS)

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ senderId: contactDetails.defaultSenderId }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <ExtensionContactFormFields
            {...props}
            processing={submitting}
            default="mobilephone"
          />
        </form>
      )}
    </Form>
  )
}

export default ExtensionContactForm
