import _get from 'lodash/get'
import { configureStore } from '@reduxjs/toolkit'
import reducers from './reducers'

const IS_PRODUCTION = _get(process.env, 'REACT_APP_BUILD_ENV', 'production') === 'production'

const store = configureStore({
  reducer: reducers,
  devTools: !IS_PRODUCTION,
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
