import { ConfigProvider } from 'antd'
import moment from 'moment'
import LOCALE_EN_US from 'antd/es/locale/en_US'
import LOCALE_DE_DE from 'antd/es/locale/de_DE'
import LOCALE_IT_IT from 'antd/es/locale/it_IT'
import LOCALE_PT_BR from 'antd/es/locale/pt_BR'
import LOCALE_ES_ES from 'antd/es/locale/es_ES'
import LOCALE_FR_FR from 'antd/es/locale/fr_FR'
import _get from 'lodash/get'
import type { ReactNode } from 'react'
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/es-mx'
import 'moment/locale/pt-br'

import type { IntlMeta } from '../core/mfe-types'

import EN_US from '../../translations/en-US.json'
import DE_DE from '../../translations/de-DE.json'
import IT_IT from '../../translations/it-IT.json'
import ES_MX from '../../translations/es-MX.json'
import PT_BR from '../../translations/pt-BR.json'
import FR from '../../translations/fr-FR.json'

import type { Translations } from './i18n-types'

type IntlProviderProps = { children: ReactNode };

type Locale = IntlMeta['locale'];

const getIntlLocale = () => _get(window, 'meta.intl.locale') || navigator.language.split('-')[0] || 'en'

const withEnglishFallback = (other: Partial<Translations> = {}): Translations => ({ ...EN_US, ...other })

export const localeToTranslations = (locale: Locale): Translations => {
  // Would have been nice to be able to assert that all translations exist in
  // all json files. However they only get added after a feature is merged..
  // TODO: Think of something! ;D
  const map: Record<Locale, Partial<Translations>> = {
    en: EN_US,
    'en-US': EN_US,
    de: DE_DE,
    'de-DE': DE_DE,
    it: IT_IT,
    'it-IT': IT_IT,
    es: ES_MX,
    'es-MX': ES_MX,
    pt: PT_BR,
    'pt-BR': PT_BR,
    fr: FR,
    'fr-FR': FR,
  }

  return withEnglishFallback(map[locale])
}

export const getAntdLocale = (locale: Locale) => {
  const map = {
    en: LOCALE_EN_US,
    'en-US': LOCALE_EN_US,
    de: LOCALE_DE_DE,
    'de-DE': LOCALE_DE_DE,
    it: LOCALE_IT_IT,
    'it-IT': LOCALE_IT_IT,
    pt: LOCALE_PT_BR,
    'pt-BR': LOCALE_PT_BR,
    es: LOCALE_PT_BR,
    'es-MX': LOCALE_ES_ES,
    fr: LOCALE_FR_FR,
    'fr-FR': LOCALE_FR_FR,
  }

  return _get(map, locale, map['en-US'])
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

export const intl = createIntl({
  locale: getIntlLocale(),
  messages: localeToTranslations(getIntlLocale()),
  defaultLocale: 'en-US',
}, cache)

export const { formatNumber, formatMessage } = intl

export const IntlProvider = ({ children }: IntlProviderProps) => {
  const locale = getIntlLocale()
  moment.locale(locale)

  return (
    <ConfigProvider locale={getAntdLocale(locale)}>
      <RawIntlProvider value={intl}>
        {children}
      </RawIntlProvider>
    </ConfigProvider>
  )
}
