import ENDPOINT from '../helpers/endpoint'
import { TQueryState, TSendMessageParams } from '../ui/extension-contact/extension-contact-types'
import { TChannelConfiguration } from '../redux/channel/channel-actions'

const ExtensionContactApi = {
  getContactDetails: (query: TQueryState, search: URLSearchParams) => fetch(`${ENDPOINT.defaultUri}/display/${query.objectType}/${query.uuid}?${search.toString()}`),
  sendMessage: ({ query, body }: TSendMessageParams, search: URLSearchParams) => fetch(
    `${ENDPOINT.defaultUri}/send/${query.objectType}/${query.uuid}?${search.toString()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  ),
  postSurveyResponse: (query: { objectType: string, uuid: string, otp: string, formId?: string, responseId?: string}, completed: boolean) => fetch(
    `${ENDPOINT.defaultUri}/typeformSurvey/${query.objectType}/${query.uuid}?otp=${query.otp}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ completed, formId: query.formId, responseId: query.responseId }),
    },
  ),
  fetchSenderIds: (query: { portalId: string }) => fetch(
    `${ENDPOINT.customChannel}/senderIds?portalId=${query.portalId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  ),
  sendConfiguration: (body: TChannelConfiguration) => fetch(
    `${ENDPOINT.customChannel}/save`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    },
  ),
}

export default ExtensionContactApi
