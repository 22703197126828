/* eslint @typescript-eslint/no-explicit-any: off */
import React from 'react'

const withFinalForm = (mapToProps?: any) => (WrappedComponent: any) => {
  const Component = (props: any) => {
    const { input, ...custom } = props
    let mappedProps = typeof mapToProps === 'function' ? mapToProps(props) : {}
    mappedProps = Object.prototype.toString.call(mappedProps) === '[object Object]' ? mappedProps : {}
    return (
      <WrappedComponent {...input} {...custom} {...mappedProps} />
    )
  }

  Component.displayName = WrappedComponent.displayName

  return Component
}

export default withFinalForm
