import React from 'react'
import _get from 'lodash/get'
import { Field, useFormState, useForm } from 'react-final-form'
import Card from 'antd/lib/card'
import Tooltip from 'antd/lib/tooltip'
import Select from 'antd/lib/select'
import Modal from 'antd/lib/modal'
import { InfoCircleOutlined, ExclamationCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'

import Heading from '../../core/heading'
import withFinalForm from '../../core/with-final-form'
import WhenFieldChanges from '../../when-field-changes'
import getPhoneNumberFormat from '../../../helpers/get-phone-number-format'
import checkMMSCapability from '../../../helpers/check-mms-capability/check-mms-capability'
import withValidation from '../../../hocs/with-validation'
import SelectRecipientPhone from '../extension-contact-form/select-recipient-phone'

import { useAppSelector } from '../../../store/hooks'
import {
  getAccountsAndSenderIds, getDetails, getIsTrialUser, getPhoneNumbers,
} from '../../../redux/contact/extension-contact-selectors'

import {
  TExtensionContactFormFieldProps,
  TShowMMSNotSupportedModalParams,
  TFormValues,
} from '../extension-contact-types'
import { TAB_MMS, TAB_SMS } from '../../../constants/extension-contact'
import { needToVerifyTFN } from '../../../helpers/tfn-verification/tfn-verification'

import './sender-and-recipients-card.css'
import { getSelfServeOnboardingFlag } from '../../../redux/release-flags/release-flags-selector'
import SenderIdHelperText from '../sender-id-helper-text'
import { validatePhoneNumber } from '../../../helpers/form-validations/form-validations'

const MM_URL = _get(process.env, 'REACT_APP_MM_URL', 'https://hub.messagemedia.com')
const verifyLink = (phoneNum: string) => `${MM_URL}/tfnverify?number=${phoneNum}`

export type TSenderAndRecipientsCardProps = {
  invalidSenderId?: boolean
} & TExtensionContactFormFieldProps

const { Option } = Select

const SelectComp = withFinalForm()(Select)
const SelectRecipientPhoneWithValidation = withValidation(SelectRecipientPhone)

function SenderAndRecipientsCard(props: TSenderAndRecipientsCardProps) {
  const {
    defaultSenderId, senderIds, fields, default: defaultRecipient,
    defaultAccountId,
  } = useAppSelector(getDetails)
  const phoneNumberOptions = useAppSelector(getPhoneNumbers)
  const isTrial = useAppSelector(getIsTrialUser)
  const isSelfServeEnabled = useAppSelector(getSelfServeOnboardingFlag)
  const isTrialUser = isSelfServeEnabled && isTrial
  const phoneSourceFields = isTrialUser ? { phone: 'Phone Number' } : fields
  const {
    processing,
    invalidSenderId,
  } = props
  const {
    accountIds,
    accountsAndSenderIds,
    hasMultiAccount,
  } = useAppSelector(getAccountsAndSenderIds)

  const [prevSenderId, setPrevSenderId] = React.useState<string>(defaultSenderId)
  const { values } = useFormState<TFormValues>()
  const { change } = useForm()
  const intl = useIntl()

  const showMMSNotSupportedModal = ({ value }: TShowMMSNotSupportedModalParams) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'mms.not-supported.title' }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: 'mms.not-supported.content' }),
      onOk: () => {
        change('senderId', value)
        change('mediaURL', undefined)
        change('tabMessageContent', TAB_SMS)
      },
      onCancel: () => {
        change('senderId', prevSenderId)
      },
    })
  }

  const handleChangeSenderId = (value: string) => {
    const isMMSCapable = checkMMSCapability(senderIds, value)
    if (values.tabMessageContent === TAB_MMS && !isMMSCapable) {
      showMMSNotSupportedModal({ value })
    } else {
      setPrevSenderId(value)
      change('senderId', value)
      if (!isMMSCapable) {
        change('tabMessageContent', TAB_SMS)
      }
    }
  }

  const handleChangeAccountId = () => {
    change('senderId', undefined)
  }

  const selectSenderIds = () => {
    if (hasMultiAccount) {
      if (values.accountId) {
        const accountAndSenderId = accountsAndSenderIds?.filter((a) => a.accountKey === values.accountId)[0]
        return _get(accountAndSenderId, 'accountSenderIds', [])
      }
      return []
    }
    return senderIds
  }

  const getDefaultAccountId = () => {
    if (hasMultiAccount && defaultAccountId) {
      const found = accountsAndSenderIds?.find((a) => a.accountKey === defaultAccountId)
      if (found) {
        return defaultAccountId
      }
    }
    return undefined
  }

  const getDefaultSenderId = () => {
    if (hasMultiAccount && defaultAccountId) {
      const foundAccount = accountsAndSenderIds?.find((a) => a.accountKey === defaultAccountId)
      if (foundAccount) {
        const foundSenderId = foundAccount.accountSenderIds.find((a) => a.number === defaultSenderId)
        if (foundSenderId) {
          return defaultSenderId
        }
      }
    } else {
      const foundSenderId = senderIds.find((a) => a.number === defaultSenderId)
      if (foundSenderId) {
        return defaultSenderId
      }
    }
    return undefined
  }

  return (
    <Card
      className="card sender-and-recipients"
      title={intl.formatMessage({ id: 'sender-and-recipients.card.title' })}
      data-test="sender-and-recipients-card"
    >
      {
        hasMultiAccount
        && (
          <>
            <Heading weight={6} className="header">
              <FormattedMessage id="sender-and-recipients.card.account-id.heading" />
            </Heading>
            <Field
              name="accountId"
              className={classNames('sender-id')}
              component={SelectComp}
              disabled={processing || isTrialUser}
              defaultValue={getDefaultAccountId()}
            >
              {accountIds && accountIds.length > 1 && accountIds.map((a) => (
                <Option key={a.accountKey} value={a.accountKey} data-test={a.accountKey}>
                  {a.accountLabel}
                </Option>
              ))}
            </Field>
            <WhenFieldChanges
              field="accountId"
              cb={() => values.accountId && handleChangeAccountId()}
            />
          </>
        )
      }
      <Heading weight={6} className="header">
        <FormattedMessage id="sender-and-recipients.card.sender-id.heading" />
        <Tooltip
          placement="right"
          title={intl.formatMessage({ id: 'sender-and-recipients.card.sender-id.heading.tooltip' })}
        >
          <InfoCircleOutlined className="tooltip" />
        </Tooltip>
      </Heading>
      <WhenFieldChanges
        field="senderId"
        cb={() => values.senderId && handleChangeSenderId(values.senderId)}
      />
      <Field
        defaultValue={getDefaultSenderId()}
        name="senderId"
        className={classNames('sender-id', { 'tfn-error': invalidSenderId })}
        component={SelectComp}
        showSearch
        optionFilterProp="children"
        disabled={processing || isTrialUser || (hasMultiAccount && !values.accountId)}
      >
        {
          Option && selectSenderIds().map((v) => (
            <Option key={v.number} value={v.number} data-test={v.number}>
              {v.displayLabel}
              {
                isSelfServeEnabled
                && !isTrialUser
                && needToVerifyTFN(v)
                && (
                  <a
                    href={verifyLink(v.number)}
                    target="_blank"
                    data-test="tfn-verify-text"
                    className="verify-text"
                  >
                    <FormattedMessage
                      id="sender-and-recipients.card.sender-id.verify"
                    />
                  </a>
                )
              }
            </Option>
          ))
        }
      </Field>
      <SenderIdHelperText />
      {
        values.senderId
        && invalidSenderId
        && (
          <div className="tfn-error-message" data-test="tfn-error-message">
            <ExclamationCircleFilled className="tfn-invalid-warning" />
            <p>
              <FormattedMessage
                id="sender-and-recipients.card.sender-id.tfn-error"
                values={{
                  link: (
                    <a
                      href={verifyLink(values.senderId)}
                      target="_blank"
                      data-test="tfn-verify-your-number"
                      className="verify-text-link"
                    >
                      <FormattedMessage
                        id="sender-and-recipients.card.sender-id.tfn-error.link"
                      />
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        )
      }

      <Heading weight={6} className="header">
        <FormattedMessage id="sender-and-recipients.card.recipients.heading" />
      </Heading>
      <Field
        fields={phoneSourceFields}
        name="phoneSource"
        disabled={processing}
        defaultValue={(isTrialUser ? 'phone' : defaultRecipient) as string}
        component={SelectRecipientPhoneWithValidation as React.FC}
        displayValue={getPhoneNumberFormat(undefined, phoneNumberOptions, values.phoneSource)}
        defaultRecipient={getPhoneNumberFormat(undefined, phoneNumberOptions, values.phoneSource)}
        validate={validatePhoneNumber(phoneNumberOptions)}
      />
    </Card>
  )
}

export default SenderAndRecipientsCard
